export default function getLocalQueries({ cardType, visualization, interactiveTableColumns, localDataset, isPreview, queries, fieldsTree }) {

    // Initialize local queries array
    let localQueries = [];
    let localQueryFields = [];

    // Check if have visualization metadata
    if (!visualization || typeof visualization !== "object") return localQueries;

    if (isPreview && localDataset && queries) {
        let fields = [];
        queries.forEach((query) => {
            if (localDataset.queries.includes(query.queryId)) {
                query.fields.forEach((field) => {
                    fields.push({
                        fieldId: field._id,
                        field_type: field.field_type,
                        queryField: {
                            position: field.position,
                            query: query.queryId
                        }
                    });
                })
            }
        });

        localQueries.push({
            _id: "LQ1",
            fields: fields,
            localDataset: localDataset._id
        });
        return localQueries;
    }

    switch (cardType) {
        case "KPI":
            // Get kpi groups
            const kpiGroups = visualization.kpiGroups;
            let kpiLocalQueries = [];

            // Add visualization fields
            kpiGroups.forEach((kpiGroup) => {
                const kpiGroupItems = kpiGroup.kpiItems;
                if (Array.isArray(kpiGroupItems) && kpiGroupItems.length > 0) {
                    kpiGroupItems.forEach((kpiItem) => {
                        let lqIndex = kpiLocalQueries.findIndex(lq => lq?._id === kpiItem?.local_query_field?.local_dataset);
                        if (lqIndex >= 0) {
                            kpiLocalQueries[lqIndex].fields.push(kpiItem?.local_query_field);
                        } else {
                            kpiLocalQueries.push({
                                _id: kpiItem?.local_query_field?.local_dataset,
                                fields: [kpiItem?.local_query_field],
                                localDataset: kpiItem?.local_query_field?.local_dataset
                            });
                        }
                    });
                }
            });

            // Add fields needed to process calculated fields in visualization
            fieldsTree?.forEach((field) => {
                let lqIndex = kpiLocalQueries.findIndex(lq => lq?._id === field?.localDatasets?.[0]);
                let lqField = {
                    fieldId: field.fieldId || field.refFieldId,
                    localDataset: field?.localDatasets?.[0],
                    query: "FQ",
                    refFieldId: field?.refFieldId,
                    queryField: {
                        query: field?.query,
                        position: field?.position
                    }
                }

                // Add aggregation if exists, if not add
                lqField.aggregation = lqIndex >= 0 && kpiLocalQueries[lqIndex]?.fields?.find((f) => f?.queryField?.query === lqField?.queryField?.query && f?.queryField?.position === lqField?.queryField?.position)?.aggregation;
                if (lqIndex >= 0 && !lqField.aggregation && kpiLocalQueries[lqIndex]?.fields.some((f) => !!f.aggregation)) lqField.aggregation = 'max';


                if (lqIndex >= 0) {
                    kpiLocalQueries[lqIndex].fields.push(lqField);
                } else {
                    kpiLocalQueries.push({
                        _id: field?.localDatasets?.[0],
                        fields: [lqField],
                        localDataset: field?.localDatasets?.[0]
                    });
                }
            });

            if (kpiLocalQueries?.length > 0) {
                kpiLocalQueries.forEach((kpiLq) => {
                    localQueries.push(kpiLq);
                });
            }
            break;

        case "VerticalTable":
            const getLocalQueryFieldsFromTableColumns = tableColumns => tableColumns.map(column => column && column.local_query_field);
            if (Array.isArray(interactiveTableColumns)) {
                localQueryFields = getLocalQueryFieldsFromTableColumns(interactiveTableColumns);
            }
            else if (Array.isArray(visualization.table_columns)) {
                localQueryFields = getLocalQueryFieldsFromTableColumns(visualization.table_columns);
            }

            // Add extra fields needed to process calculated fields in visualization
            localQueryFields = getExtraFields({ fieldsTree: fieldsTree, cardFields: localQueryFields });

            localQueries.push({
                _id: "LQ1",
                localDataset: visualization.table_settings.local_dataset,
                fields: localQueryFields
            });
            break;

        case "CrossTable":

            let fields = [];
            fields = fields.concat(Array.isArray(visualization.columns) && visualization.columns.map(column => column && column.local_query_field));
            fields = fields.concat(Array.isArray(visualization.rows) && visualization.rows.map(column => column && column.local_query_field));
            fields = fields.concat(Array.isArray(visualization.metrics) && visualization.metrics.map(column => column && column.local_query_field));

            // Add extra fields needed to process calculated fields in visualization
            fields = getExtraFields({ fieldsTree: fieldsTree, cardFields: fields });

            localQueries.push({
                _id: "LQ1",
                fields: fields,
                localDataset: visualization.table_settings.local_dataset,
            });
            break;

        case "Chart":

            let chartFields = [];
            chartFields = chartFields.concat(Array.isArray(visualization.metrics) && visualization.metrics.map(metric => metric && metric.local_query_field));
            if (visualization.dimension && Object.keys(visualization.dimension).length > 0) chartFields.push(visualization.dimension && visualization.dimension.local_query_field);
            if (visualization.break_by && Object.keys(visualization.break_by).length > 0) chartFields.push(visualization.break_by && visualization.break_by.local_query_field);
            if (visualization.radius && Object.keys(visualization.radius).length > 0) chartFields.push(visualization.radius && visualization.radius.local_query_field);
            if (visualization.threshold && Object.keys(visualization.threshold).length > 0) chartFields.push(visualization.threshold && visualization.threshold.local_query_field);
            if (visualization.graph_config?.max_value_field && Object.keys(visualization.graph_config?.max_value_field)?.length > 0) chartFields.push(visualization.graph_config.max_value_field && visualization.graph_config.max_value_field.local_query_field);
            if (visualization.graph_config?.min_value_field && Object.keys(visualization.graph_config?.min_value_field)?.length > 0) chartFields.push(visualization.graph_config.min_value_field && visualization.graph_config.min_value_field.local_query_field);
            if (visualization.heatmap && Object.keys(visualization.heatmap).length > 0) chartFields.push(visualization.heatmap && visualization.heatmap.local_query_field);


            // Add extra fields needed to process calculated fields in visualization
            chartFields = getExtraFields({ fieldsTree: fieldsTree, cardFields: chartFields });

            localQueries.push({
                _id: "LQ1",
                fields: chartFields,
                localDataset: visualization.graph_config.local_dataset,
            });
            break;

        case "Map":

            // Loop through each map layer, each layer is a local query
            for (let mapLayer of visualization.map_layers) {

                // Color field
                if (!mapLayer.color.fixed && !!mapLayer.color.local_query_field) {
                    localQueryFields.push(mapLayer.color.local_query_field);
                }

                // Tooltip fields
                if (mapLayer.tooltip.show && mapLayer.tooltip.fields) {
                    for (let tooltipItem of mapLayer.tooltip.fields) {
                        !!tooltipItem.local_query_field && localQueryFields.push(tooltipItem.local_query_field);
                    }
                }

                // Popup fields
                if (mapLayer.popup.show && mapLayer.popup.fields) {
                    for (let popupItem of mapLayer.popup.fields) {
                        !!popupItem.local_query_field && localQueryFields.push(popupItem.local_query_field);
                    }
                }

                // Exclusive symbol layer fields
                if (mapLayer.layer_type === 'symbol') {
                    // Symbol size field
                    if (!mapLayer.symbol.size.fixed && !!mapLayer.symbol.size.local_query_field) {
                        localQueryFields.push(mapLayer.symbol.size.local_query_field);
                    }
                }

                // Location matching fields
                if (mapLayer.location_defined_by === 'administrative_regions' && !!mapLayer.matching_field) {
                    localQueryFields.push(mapLayer.matching_field);
                }
                else if (mapLayer.location_defined_by === 'lat_lng_fields') {
                    !!mapLayer.lat_field && localQueryFields.push(mapLayer.lat_field);
                    !!mapLayer.lng_field && localQueryFields.push(mapLayer.lng_field);
                }

                localQueries.push({
                    _id: mapLayer.local_query_id,
                    fields: localQueryFields,
                    localDataset: mapLayer.local_query_id || 'LQ1',
                });

            }
            break;

        default:
            break;
    }
    return localQueries;
}

function getExtraFields({ fieldsTree = [], cardFields = [] }) {

    fieldsTree?.forEach((field) => {
        let cardField = {
            fieldId: field.fieldId || field.refFieldId,
            localDataset: field?.localDatasets?.[0],
            query: "FQ",
            refFieldId: field?.refFieldId,
            queryField: {
                query: field?.query,
                position: field?.position
            }
        };

        // Add aggregation if needed
        cardField.aggregation = cardFields.find((cf) => cf?.queryField?.query === field.query && cf?.queryField?.position === field.position)?.aggregation;
        if (!cardField.aggregation && cardFields.some((f) => !!f.aggregation)) cardField.aggregation = 'max';

        cardFields.push(cardField);
    });

    return cardFields;
}