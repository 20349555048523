export const PAGE_BODY_ID = "pageBody"
export const CARD_SCREEN_WRAPPER_ID = "cardScreenWrapper"
export const CARD_SCREEN_BODY_ID = "cardScreenBody"
export const CARD_BODY_CLASS = "card-body"
export const PAGE_HEADER_ID = "pageHeader"
export const PAGE_HEADER_WRAPPER_ID = "pageHeaderWrapper"
export const DRAWER_MENU_BUTTON_ID = "btn-drawer-menu"
export const PAGE_HEADER_UTILS_ID = "pageHeaderUtils"
export const FILTERS_AREA_CLASS = "bw-filters-area"
export const CARD_FLOAT_AREA_CLASS = "card-float-area"
export const PAGE_CONTAINER_CLASS = "page-content-wrapper"
export const COLLECTION_TABS_CLASS = "bw-collection-tabs-wrapper"
export const PAGE_HEADER_CLASS = "bw-page-header"
export const PAGE_HEADER_TITLE_CLASS = "bw-header-title"
export const PAGE_HEADER_DESCRIPTION_CLASS = "bw-header-desc"
export const CARD_VIEW_CLASS = "bw-card-view"