function id(x) { return x[0]; }
const grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "main", "symbols": ["_", "expression", "_"], "postprocess":  function (data) {
            const [start, expression, end] = data;
            return expression;
        } },
    {"name": "expression", "symbols": ["as_operation"], "postprocess":  function (data) {
        const expression = data[0];
        if (["add", "subtract", "product", "divide"].includes(expression.type)) {
                return {
                    type: "operation",
                    operation: expression
                };
            }
            else {
                return expression;
            }
        } },
    {"name": "pd_expression", "symbols": ["field"], "postprocess":  function (data) {
            return {
                type: "field",
                field: data[0]
            };
        } },
    {"name": "pd_expression", "symbols": ["number"], "postprocess":  function (data) {
            return {
                type: "number",
                number: data[0]
            };
        } },
    {"name": "pd_expression", "symbols": ["string"], "postprocess":  function (data) {
            return {
                type: "string",
                string: data[0]
            };
        } },
    {"name": "pd_expression$string$1", "symbols": [{"literal":"n"}, {"literal":"u"}, {"literal":"l"}, {"literal":"l"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pd_expression", "symbols": ["pd_expression$string$1"], "postprocess":  function () {
            return {
                type: "null"
            };
        } },
    {"name": "pd_expression", "symbols": ["function"], "postprocess":  function (data) {
            return {
                type: "operation",
                operation: data[0]
            };
        } },
    {"name": "parenthesis", "symbols": [{"literal":"("}, "_", "as_operation", "_", {"literal":")"}], "postprocess":  function (data) {
            const [start, sws, asOperation, ews, end] = data;
            return asOperation;
        } },
    {"name": "parenthesis", "symbols": ["pd_expression"], "postprocess": id},
    {"name": "as_operation$subexpression$1", "symbols": [{"literal":"+"}]},
    {"name": "as_operation$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "as_operation", "symbols": ["as_operation", "_", "as_operation$subexpression$1", "_", "pd_operation"], "postprocess":  function (data) {
            const [asOperation, ws1, operation, ws2, pdOperation] = data;
            const evaluateOperator = (operation) => {
                if (["add", "subtract", "product", "divide"].includes(operation.type)) {
                    return {
                        type: "operation",
                        operation: operation
                    };
                }
                else {
                    return operation;
                }
            };
            return {
                type: operation[0] === "+" ? "add" : "subtract",
                extraArgs: {
                    operator1: evaluateOperator(asOperation),
                    operator2: evaluateOperator(pdOperation)
                }
            };
        } },
    {"name": "as_operation", "symbols": ["pd_operation"], "postprocess": id},
    {"name": "pd_operation$subexpression$1", "symbols": [{"literal":"*"}]},
    {"name": "pd_operation$subexpression$1", "symbols": [{"literal":"/"}]},
    {"name": "pd_operation", "symbols": ["pd_operation", "_", "pd_operation$subexpression$1", "_", "parenthesis"], "postprocess":  function (data) {
            const [operator1, ws1, operation, ws2, operator2] = data;
            const evaluateOperator = (operation) => {
                if (["add", "subtract", "product", "divide"].includes(operation.type)) {
                    return {
                        type: "operation",
                        operation: operation
                    };
                }
                else {
                    return operation;
                }
            };
            return {
                type: operation[0] === "*" ? "product" : "divide",
                extraArgs: {
                    operator1: evaluateOperator(operator1),
                    operator2: evaluateOperator(operator2),
                }
            };
        } },
    {"name": "pd_operation", "symbols": ["parenthesis"], "postprocess": id},
    {"name": "function", "symbols": ["running_sum"], "postprocess": id},
    {"name": "function", "symbols": ["running_avg"], "postprocess": id},
    {"name": "function", "symbols": ["running_min"], "postprocess": id},
    {"name": "function", "symbols": ["running_max"], "postprocess": id},
    {"name": "function", "symbols": ["running_count"], "postprocess": id},
    {"name": "function", "symbols": ["moving_sum"], "postprocess": id},
    {"name": "function", "symbols": ["moving_avg"], "postprocess": id},
    {"name": "function", "symbols": ["moving_min"], "postprocess": id},
    {"name": "function", "symbols": ["moving_max"], "postprocess": id},
    {"name": "function", "symbols": ["diff_from_first"], "postprocess": id},
    {"name": "function", "symbols": ["diff_from_prev"], "postprocess": id},
    {"name": "function", "symbols": ["diff_from_next"], "postprocess": id},
    {"name": "function", "symbols": ["diff_from_last"], "postprocess": id},
    {"name": "function", "symbols": ["pct_diff_from_first"], "postprocess": id},
    {"name": "function", "symbols": ["pct_diff_from_prev"], "postprocess": id},
    {"name": "function", "symbols": ["pct_diff_from_next"], "postprocess": id},
    {"name": "function", "symbols": ["pct_diff_from_last"], "postprocess": id},
    {"name": "function", "symbols": ["pct_from_first"], "postprocess": id},
    {"name": "function", "symbols": ["pct_from_prev"], "postprocess": id},
    {"name": "function", "symbols": ["pct_from_next"], "postprocess": id},
    {"name": "function", "symbols": ["pct_from_last"], "postprocess": id},
    {"name": "function", "symbols": ["total_sum"], "postprocess": id},
    {"name": "function", "symbols": ["pct_over_total"], "postprocess": id},
    {"name": "function", "symbols": ["total_avg"], "postprocess": id},
    {"name": "function", "symbols": ["total_min"], "postprocess": id},
    {"name": "function", "symbols": ["total_max"], "postprocess": id},
    {"name": "function", "symbols": ["total_count"], "postprocess": id},
    {"name": "function", "symbols": ["total_count_distinct"], "postprocess": id},
    {"name": "function", "symbols": ["median"], "postprocess": id},
    {"name": "function", "symbols": ["mode"], "postprocess": id},
    {"name": "function", "symbols": ["standard_deviation"], "postprocess": id},
    {"name": "function", "symbols": ["percentile"], "postprocess": id},
    {"name": "function", "symbols": ["quartile"], "postprocess": id},
    {"name": "function", "symbols": ["tercile"], "postprocess": id},
    {"name": "function", "symbols": ["variance"], "postprocess": id},
    {"name": "function", "symbols": ["correlation"], "postprocess": id},
    {"name": "function", "symbols": ["prev_year"], "postprocess": id},
    {"name": "function", "symbols": ["prev_quarter"], "postprocess": id},
    {"name": "function", "symbols": ["prev_month"], "postprocess": id},
    {"name": "function", "symbols": ["prev_week"], "postprocess": id},
    {"name": "function", "symbols": ["prev_day"], "postprocess": id},
    {"name": "function", "symbols": ["prev_period"], "postprocess": id},
    {"name": "function", "symbols": ["if"], "postprocess": id},
    {"name": "function", "symbols": ["mod"], "postprocess": id},
    {"name": "function", "symbols": ["concat"], "postprocess": id},
    {"name": "function", "symbols": ["contains"], "postprocess": id},
    {"name": "function", "symbols": ["ends_with"], "postprocess": id},
    {"name": "function", "symbols": ["starts_with"], "postprocess": id},
    {"name": "function", "symbols": ["find"], "postprocess": id},
    {"name": "function", "symbols": ["findnth"], "postprocess": id},
    {"name": "function", "symbols": ["left"], "postprocess": id},
    {"name": "function", "symbols": ["len"], "postprocess": id},
    {"name": "function", "symbols": ["capitalize"], "postprocess": id},
    {"name": "function", "symbols": ["lower"], "postprocess": id},
    {"name": "function", "symbols": ["ltrim"], "postprocess": id},
    {"name": "function", "symbols": ["substring"], "postprocess": id},
    {"name": "function", "symbols": ["replace"], "postprocess": id},
    {"name": "function", "symbols": ["right"], "postprocess": id},
    {"name": "function", "symbols": ["rtrim"], "postprocess": id},
    {"name": "function", "symbols": ["space"], "postprocess": id},
    {"name": "function", "symbols": ["split"], "postprocess": id},
    {"name": "function", "symbols": ["trim"], "postprocess": id},
    {"name": "function", "symbols": ["upper"], "postprocess": id},
    {"name": "function", "symbols": ["max_value"], "postprocess": id},
    {"name": "function", "symbols": ["min_value"], "postprocess": id},
    {"name": "function", "symbols": ["power"], "postprocess": id},
    {"name": "function", "symbols": ["round"], "postprocess": id},
    {"name": "function", "symbols": ["floor"], "postprocess": id},
    {"name": "function", "symbols": ["ceiling"], "postprocess": id},
    {"name": "function", "symbols": ["sign"], "postprocess": id},
    {"name": "function", "symbols": ["sqrt"], "postprocess": id},
    {"name": "function", "symbols": ["square"], "postprocess": id},
    {"name": "function", "symbols": ["exp"], "postprocess": id},
    {"name": "function", "symbols": ["abs"], "postprocess": id},
    {"name": "function", "symbols": ["div"], "postprocess": id},
    {"name": "function", "symbols": ["ln"], "postprocess": id},
    {"name": "function", "symbols": ["log"], "postprocess": id},
    {"name": "function", "symbols": ["date"], "postprocess": id},
    {"name": "function", "symbols": ["dateparse"], "postprocess": id},
    {"name": "function", "symbols": ["makedate"], "postprocess": id},
    {"name": "function", "symbols": ["dateadd"], "postprocess": id},
    {"name": "function", "symbols": ["datediff"], "postprocess": id},
    {"name": "function", "symbols": ["datename"], "postprocess": id},
    {"name": "function", "symbols": ["datepart"], "postprocess": id},
    {"name": "function", "symbols": ["datetrunc"], "postprocess": id},
    {"name": "function", "symbols": ["year"], "postprocess": id},
    {"name": "function", "symbols": ["quarter"], "postprocess": id},
    {"name": "function", "symbols": ["month"], "postprocess": id},
    {"name": "function", "symbols": ["week"], "postprocess": id},
    {"name": "function", "symbols": ["day"], "postprocess": id},
    {"name": "function", "symbols": ["isoyear"], "postprocess": id},
    {"name": "function", "symbols": ["isoweek"], "postprocess": id},
    {"name": "function", "symbols": ["isoweekday"], "postprocess": id},
    {"name": "function", "symbols": ["today"], "postprocess": id},
    {"name": "function", "symbols": ["isdate"], "postprocess": id},
    {"name": "function", "symbols": ["sum"], "postprocess": id},
    {"name": "function", "symbols": ["avg"], "postprocess": id},
    {"name": "function", "symbols": ["count"], "postprocess": id},
    {"name": "function", "symbols": ["count_distinct"], "postprocess": id},
    {"name": "function", "symbols": ["max"], "postprocess": id},
    {"name": "function", "symbols": ["min"], "postprocess": id},
    {"name": "function", "symbols": ["ifnull"], "postprocess": id},
    {"name": "total_sum$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"S"}, {"literal":"U"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_sum", "symbols": ["total_sum$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_sum",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_over_total$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"O"}, {"literal":"V"}, {"literal":"E"}, {"literal":"R"}, {"literal":"_"}, {"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_over_total", "symbols": ["pct_over_total$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_over_total",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "total_avg$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"A"}, {"literal":"V"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_avg", "symbols": ["total_avg$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_avg",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "total_min$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"M"}, {"literal":"I"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_min", "symbols": ["total_min$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_min",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "total_max$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"M"}, {"literal":"A"}, {"literal":"X"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_max", "symbols": ["total_max$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_max",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "total_count$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"C"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_count", "symbols": ["total_count$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_count",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "total_count_distinct$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"_"}, {"literal":"C"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"S"}, {"literal":"T"}, {"literal":"I"}, {"literal":"N"}, {"literal":"C"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "total_count_distinct", "symbols": ["total_count_distinct$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "total_count_distinct",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "median$string$1", "symbols": [{"literal":"M"}, {"literal":"E"}, {"literal":"D"}, {"literal":"I"}, {"literal":"A"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "median", "symbols": ["median$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "median",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        }},
    {"name": "mode$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"D"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "mode", "symbols": ["mode$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "mode",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        }},
    {"name": "standard_deviation$string$1", "symbols": [{"literal":"S"}, {"literal":"T"}, {"literal":"A"}, {"literal":"N"}, {"literal":"D"}, {"literal":"A"}, {"literal":"R"}, {"literal":"D"}, {"literal":"_"}, {"literal":"D"}, {"literal":"E"}, {"literal":"V"}, {"literal":"I"}, {"literal":"A"}, {"literal":"T"}, {"literal":"I"}, {"literal":"O"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "standard_deviation", "symbols": ["standard_deviation$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "standard_deviation",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        }},
    {"name": "percentile$string$1", "symbols": [{"literal":"P"}, {"literal":"E"}, {"literal":"R"}, {"literal":"C"}, {"literal":"E"}, {"literal":"N"}, {"literal":"T"}, {"literal":"I"}, {"literal":"L"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "percentile", "symbols": ["percentile$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, percentile, ewsf2, endFn] = data;
            return {
                type: "percentile",
                extraArgs: {
                    type: "field",
                    field,
                    percentile
                }
            };
        }},
    {"name": "quartile$string$1", "symbols": [{"literal":"Q"}, {"literal":"U"}, {"literal":"A"}, {"literal":"R"}, {"literal":"T"}, {"literal":"I"}, {"literal":"L"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "quartile", "symbols": ["quartile$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, quartile, ewsf2, endFn] = data;
            return {
                type: "quartile",
                extraArgs: {
                    type: "field",
                    field,
                    quartile
                }
            };
        }},
    {"name": "tercile$string$1", "symbols": [{"literal":"T"}, {"literal":"E"}, {"literal":"R"}, {"literal":"C"}, {"literal":"I"}, {"literal":"L"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "tercile", "symbols": ["tercile$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, tercile, ewsf2, endFn] = data;
            return {
                type: "tercile",
                extraArgs: {
                    type: "field",
                    field,
                    tercile
                }
            };
        }},
    {"name": "variance$string$1", "symbols": [{"literal":"V"}, {"literal":"A"}, {"literal":"R"}, {"literal":"I"}, {"literal":"A"}, {"literal":"N"}, {"literal":"C"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "variance", "symbols": ["variance$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "variance",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        }},
    {"name": "correlation$string$1", "symbols": [{"literal":"C"}, {"literal":"O"}, {"literal":"R"}, {"literal":"R"}, {"literal":"E"}, {"literal":"L"}, {"literal":"A"}, {"literal":"T"}, {"literal":"I"}, {"literal":"O"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "correlation", "symbols": ["correlation$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "correlation",
                extraArgs: {
                    type: "fields",
                    fieldsLength: 2,
                    field1: field1,
                    field2: field2
                }
            };
        }},
    {"name": "sum$string$1", "symbols": [{"literal":"S"}, {"literal":"U"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "sum", "symbols": ["sum$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "sum",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "avg$string$1", "symbols": [{"literal":"A"}, {"literal":"V"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "avg", "symbols": ["avg$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "avg",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "count$string$1", "symbols": [{"literal":"C"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "count", "symbols": ["count$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "count",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "count_distinct$string$1", "symbols": [{"literal":"C"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"S"}, {"literal":"T"}, {"literal":"I"}, {"literal":"N"}, {"literal":"C"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "count_distinct", "symbols": ["count_distinct$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "count_distinct",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "max$string$1", "symbols": [{"literal":"M"}, {"literal":"A"}, {"literal":"X"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "max", "symbols": ["max$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "max",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "min$string$1", "symbols": [{"literal":"M"}, {"literal":"I"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "min", "symbols": ["min$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "min",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "running_sum$string$1", "symbols": [{"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"N"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"S"}, {"literal":"U"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "running_sum", "symbols": ["running_sum$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "running_sum",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "running_avg$string$1", "symbols": [{"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"N"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"A"}, {"literal":"V"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "running_avg", "symbols": ["running_avg$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "running_avg",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "running_min$string$1", "symbols": [{"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"N"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"M"}, {"literal":"I"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "running_min", "symbols": ["running_min$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "running_min",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "running_max$string$1", "symbols": [{"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"N"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"M"}, {"literal":"A"}, {"literal":"X"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "running_max", "symbols": ["running_max$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "running_max",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "running_count$string$1", "symbols": [{"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"N"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"C"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "running_count", "symbols": ["running_count$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "running_count",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "moving_sum$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"V"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"S"}, {"literal":"U"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "moving_sum", "symbols": ["moving_sum$string$1", "_", "field", "_", {"literal":","}, "_", "int", "_", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, separator, swspr, prevRange, ewspr, separator2, swsnr, nextRange, ewsnr, endFn] = data;
            return {
                type: "moving_sum",
                extraArgs: {
                    type: "field",
                    field: field,
                    prevRange: prevRange,
                    nextRange: nextRange,
                }
            };
        }},
    {"name": "moving_avg$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"V"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"A"}, {"literal":"V"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "moving_avg", "symbols": ["moving_avg$string$1", "_", "field", "_", {"literal":","}, "_", "int", "_", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, separator, swspr, prevRange, ewspr, separator2, swsnr, nextRange, ewsnr, endFn] = data;
            return {
                type: "moving_avg",
                extraArgs: {
                    type: "field",
                    field: field,
                    prevRange: prevRange,
                    nextRange: nextRange,
                }
            };
        }},
    {"name": "moving_min$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"V"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"M"}, {"literal":"I"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "moving_min", "symbols": ["moving_min$string$1", "_", "field", "_", {"literal":","}, "_", "int", "_", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, separator, swspr, prevRange, ewspr, separator2, swsnr, nextRange, ewsnr, endFn] = data;
            return {
                type: "moving_min",
                extraArgs: {
                    type: "field",
                    field: field,
                    prevRange: prevRange,
                    nextRange: nextRange,
                }
            };
        }},
    {"name": "moving_max$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"V"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"_"}, {"literal":"M"}, {"literal":"A"}, {"literal":"X"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "moving_max", "symbols": ["moving_max$string$1", "_", "field", "_", {"literal":","}, "_", "int", "_", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, separator, swspr, prevRange, ewspr, separator2, swsnr, nextRange, ewsnr, endFn] = data;
            return {
                type: "moving_max",
                extraArgs: {
                    type: "field",
                    field: field,
                    prevRange: prevRange,
                    nextRange: nextRange,
                }
            };
        }},
    {"name": "diff_from_first$string$1", "symbols": [{"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"F"}, {"literal":"I"}, {"literal":"R"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "diff_from_first", "symbols": ["diff_from_first$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "diff_from_first",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "diff_from_prev$string$1", "symbols": [{"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "diff_from_prev", "symbols": ["diff_from_prev$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "diff_from_prev",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "diff_from_next$string$1", "symbols": [{"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"N"}, {"literal":"E"}, {"literal":"X"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "diff_from_next", "symbols": ["diff_from_next$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "diff_from_next",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "diff_from_last$string$1", "symbols": [{"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"L"}, {"literal":"A"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "diff_from_last", "symbols": ["diff_from_last$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "diff_from_last",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_diff_from_first$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"F"}, {"literal":"I"}, {"literal":"R"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_diff_from_first", "symbols": ["pct_diff_from_first$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_diff_from_first",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_diff_from_prev$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_diff_from_prev", "symbols": ["pct_diff_from_prev$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_diff_from_prev",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_diff_from_next$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"N"}, {"literal":"E"}, {"literal":"X"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_diff_from_next", "symbols": ["pct_diff_from_next$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_diff_from_next",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_diff_from_last$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"L"}, {"literal":"A"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_diff_from_last", "symbols": ["pct_diff_from_last$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_diff_from_last",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_from_first$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"F"}, {"literal":"I"}, {"literal":"R"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_from_first", "symbols": ["pct_from_first$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_from_first",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_from_prev$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_from_prev", "symbols": ["pct_from_prev$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_from_prev",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_from_next$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"N"}, {"literal":"E"}, {"literal":"X"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_from_next", "symbols": ["pct_from_next$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_from_next",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "pct_from_last$string$1", "symbols": [{"literal":"P"}, {"literal":"C"}, {"literal":"T"}, {"literal":"_"}, {"literal":"F"}, {"literal":"R"}, {"literal":"O"}, {"literal":"M"}, {"literal":"_"}, {"literal":"L"}, {"literal":"A"}, {"literal":"S"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "pct_from_last", "symbols": ["pct_from_last$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf, field, ewsf, endFn] = data;
            return {
                type: "pct_from_last",
                extraArgs: {
                    type: "field",
                    field: field
                }
            };
        }},
    {"name": "date$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date", "symbols": ["date$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, date, endFn] = data;
            return {
                type: "date",
                extraArgs: {
                    dateExpression: date,
                }
            };
        }},
    {"name": "dateparse$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"P"}, {"literal":"A"}, {"literal":"R"}, {"literal":"S"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "dateparse", "symbols": ["dateparse$string$1", "_", "string", "_", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDfWs, dateFormat, endDfWs, // Date format
                separator,
                dateExpression, // Date expression
                endFn
            ] = data;
        
            return {
                type: "dateparse",
                extraArgs: {
                    dateFormat: dateFormat,
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "makedate$string$1", "symbols": [{"literal":"M"}, {"literal":"A"}, {"literal":"K"}, {"literal":"E"}, {"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "makedate", "symbols": ["makedate$string$1", "main", {"literal":","}, "main", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                yearExpression,
                sep1,
                monthExpression,
                sep2,
                dayExpression,
                endFn
            ] = data;
            return {
                type: "makedate",
                extraArgs: {
                    yearExpression,
                    monthExpression,
                    dayExpression,
                }
            };
        }},
    {"name": "dateadd$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"A"}, {"literal":"D"}, {"literal":"D"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "dateadd", "symbols": ["dateadd$string$1", "_", "date_level", "_", {"literal":","}, "_", "number_int", "_", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDatePartWs, dateLevel, endDatePartWs, // Date level
                sep1,
                startIntWs, interval, endIntWs, // Interval
                sep2,
                dateExpression, // Date expression
                endFn
            ] = data;
        
            return {
                type: "dateadd",
                extraArgs: {
                    dateLevel: dateLevel,
                    interval: interval,
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "datediff$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"D"}, {"literal":"I"}, {"literal":"F"}, {"literal":"F"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datediff", "symbols": ["datediff$string$1", "_", "date_level", "_", {"literal":","}, "main", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDatePartWs, dateLevel, endDatePartWs, // Date level
                sep1,
                dateExpression1, // Date expression 1
                sep2,
                dateExpression2, // Date expression 2
                endFn
            ] = data;
        
            return {
                type: "datediff",
                extraArgs: {
                    dateLevel: dateLevel,
                    dateExpression1: dateExpression1,
                    dateExpression2: dateExpression2,
                }
            };
        }},
    {"name": "datename$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"N"}, {"literal":"A"}, {"literal":"M"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datename", "symbols": ["datename$string$1", "_", "date_part", "_", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDatePartWs, datePart, endDatePartWs, // Date part
                separator,
                dateExpression, // Date expression
                endFn
            ] = data;
        
            return {
                type: "datename",
                extraArgs: {
                    datePart: datePart,
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "datepart$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"P"}, {"literal":"A"}, {"literal":"R"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datepart", "symbols": ["datepart$string$1", "_", "iso_date_part", "_", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDatePartWs, isoDatePart, endDatePartWs, // ISO date part
                separator,
                dateExpression, // Date expression
                endFn
            ] = data;
        
            return {
                type: "datepart",
                extraArgs: {
                    isoDatePart: isoDatePart,
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "datetrunc$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"T"}, {"literal":"R"}, {"literal":"U"}, {"literal":"N"}, {"literal":"C"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datetrunc", "symbols": ["datetrunc$string$1", "_", "date_level", "_", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [
                startFn,
                startDatePartWs, dateLevel, endDatePartWs, // Date level
                separator,
                dateExpression, // Date expression
                endFn
            ] = data;
        
            return {
                type: "datetrunc",
                extraArgs: {
                    dateLevel: dateLevel,
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "year$string$1", "symbols": [{"literal":"Y"}, {"literal":"E"}, {"literal":"A"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "year", "symbols": ["year$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "year",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "quarter$string$1", "symbols": [{"literal":"Q"}, {"literal":"U"}, {"literal":"A"}, {"literal":"R"}, {"literal":"T"}, {"literal":"E"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "quarter", "symbols": ["quarter$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "quarter",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "month$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"N"}, {"literal":"T"}, {"literal":"H"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "month", "symbols": ["month$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "month",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "week$string$1", "symbols": [{"literal":"W"}, {"literal":"E"}, {"literal":"E"}, {"literal":"K"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "week", "symbols": ["week$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "week",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "day$string$1", "symbols": [{"literal":"D"}, {"literal":"A"}, {"literal":"Y"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "day", "symbols": ["day$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "day",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "isoyear$string$1", "symbols": [{"literal":"I"}, {"literal":"S"}, {"literal":"O"}, {"literal":"Y"}, {"literal":"E"}, {"literal":"A"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "isoyear", "symbols": ["isoyear$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "isoyear",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "isoweek$string$1", "symbols": [{"literal":"I"}, {"literal":"S"}, {"literal":"O"}, {"literal":"W"}, {"literal":"E"}, {"literal":"E"}, {"literal":"K"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "isoweek", "symbols": ["isoweek$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "isoweek",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "isoweekday$string$1", "symbols": [{"literal":"I"}, {"literal":"S"}, {"literal":"O"}, {"literal":"W"}, {"literal":"E"}, {"literal":"E"}, {"literal":"K"}, {"literal":"D"}, {"literal":"A"}, {"literal":"Y"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "isoweekday", "symbols": ["isoweekday$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "isoweekday",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "today$string$1", "symbols": [{"literal":"T"}, {"literal":"O"}, {"literal":"D"}, {"literal":"A"}, {"literal":"Y"}, {"literal":"("}, {"literal":")"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "today", "symbols": ["today$string$1"], "postprocess":  function (data) {
            return {
                type: "today",
                extraArgs: {}
            };
        }},
    {"name": "isdate$string$1", "symbols": [{"literal":"I"}, {"literal":"S"}, {"literal":"D"}, {"literal":"A"}, {"literal":"T"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "isdate", "symbols": ["isdate$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, dateExpression, endFn] = data;
            return {
                type: "isdate",
                extraArgs: {
                    dateExpression: dateExpression,
                }
            };
        }},
    {"name": "date_level$subexpression$1", "symbols": [{"literal":"\""}]},
    {"name": "date_level$subexpression$1", "symbols": [{"literal":"'"}]},
    {"name": "date_level$subexpression$2", "symbols": [{"literal":"\""}]},
    {"name": "date_level$subexpression$2", "symbols": [{"literal":"'"}]},
    {"name": "date_level", "symbols": ["date_level$subexpression$1", "date_level_options", "date_level$subexpression$2"], "postprocess":  function (data) {
            const [s1, string, s2] = data;
            return string[0];
        } },
    {"name": "date_part$subexpression$1", "symbols": [{"literal":"\""}]},
    {"name": "date_part$subexpression$1", "symbols": [{"literal":"'"}]},
    {"name": "date_part$subexpression$2", "symbols": [{"literal":"\""}]},
    {"name": "date_part$subexpression$2", "symbols": [{"literal":"'"}]},
    {"name": "date_part", "symbols": ["date_part$subexpression$1", "date_part_options", "date_part$subexpression$2"], "postprocess":  function (data) {
            const [s1, string, s2] = data;
            return string[0];
        } },
    {"name": "iso_date_part$subexpression$1", "symbols": [{"literal":"\""}]},
    {"name": "iso_date_part$subexpression$1", "symbols": [{"literal":"'"}]},
    {"name": "iso_date_part$subexpression$2", "symbols": [{"literal":"\""}]},
    {"name": "iso_date_part$subexpression$2", "symbols": [{"literal":"'"}]},
    {"name": "iso_date_part", "symbols": ["iso_date_part$subexpression$1", "iso_date_part_options", "iso_date_part$subexpression$2"], "postprocess":  function (data) {
            const [s1, string, s2] = data;
            return string[0];
        } },
    {"name": "iso_date_part_options", "symbols": ["date_part_options"]},
    {"name": "iso_date_part_options$string$1", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"o"}, {"literal":"-"}, {"literal":"y"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "iso_date_part_options", "symbols": ["iso_date_part_options$string$1"]},
    {"name": "iso_date_part_options$string$2", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"o"}, {"literal":"-"}, {"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "iso_date_part_options", "symbols": ["iso_date_part_options$string$2"]},
    {"name": "iso_date_part_options$string$3", "symbols": [{"literal":"i"}, {"literal":"s"}, {"literal":"o"}, {"literal":"-"}, {"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}, {"literal":"d"}, {"literal":"a"}, {"literal":"y"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "iso_date_part_options", "symbols": ["iso_date_part_options$string$3"]},
    {"name": "date_part_options", "symbols": ["date_level_options"]},
    {"name": "date_part_options$string$1", "symbols": [{"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}, {"literal":"d"}, {"literal":"a"}, {"literal":"y"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_part_options", "symbols": ["date_part_options$string$1"]},
    {"name": "date_part_options$string$2", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"y"}, {"literal":"o"}, {"literal":"f"}, {"literal":"y"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_part_options", "symbols": ["date_part_options$string$2"]},
    {"name": "date_level_options$string$1", "symbols": [{"literal":"y"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_level_options", "symbols": ["date_level_options$string$1"]},
    {"name": "date_level_options$string$2", "symbols": [{"literal":"q"}, {"literal":"u"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}, {"literal":"e"}, {"literal":"r"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_level_options", "symbols": ["date_level_options$string$2"]},
    {"name": "date_level_options$string$3", "symbols": [{"literal":"m"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"h"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_level_options", "symbols": ["date_level_options$string$3"]},
    {"name": "date_level_options$string$4", "symbols": [{"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_level_options", "symbols": ["date_level_options$string$4"]},
    {"name": "date_level_options$string$5", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"y"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "date_level_options", "symbols": ["date_level_options$string$5"]},
    {"name": "prev_year$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"Y"}, {"literal":"E"}, {"literal":"A"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_year", "symbols": ["prev_year$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_year",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                } 
            };
        } },
    {"name": "prev_quarter$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"Q"}, {"literal":"U"}, {"literal":"A"}, {"literal":"R"}, {"literal":"T"}, {"literal":"E"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_quarter", "symbols": ["prev_quarter$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_quarter",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                } 
            };
        } },
    {"name": "prev_month$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"M"}, {"literal":"O"}, {"literal":"N"}, {"literal":"T"}, {"literal":"H"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_month", "symbols": ["prev_month$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_month",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                } 
            };
        } },
    {"name": "prev_week$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"W"}, {"literal":"E"}, {"literal":"E"}, {"literal":"K"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_week", "symbols": ["prev_week$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_week",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                } 
            };
        } },
    {"name": "prev_day$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"D"}, {"literal":"A"}, {"literal":"Y"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_day", "symbols": ["prev_day$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_day",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                } 
            };
        } },
    {"name": "prev_period$string$1", "symbols": [{"literal":"P"}, {"literal":"R"}, {"literal":"E"}, {"literal":"V"}, {"literal":"_"}, {"literal":"P"}, {"literal":"E"}, {"literal":"R"}, {"literal":"I"}, {"literal":"O"}, {"literal":"D"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "prev_period", "symbols": ["prev_period$string$1", "_", "field", "_", {"literal":","}, "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field1, ewsf1, separator, swsf2, field2, ewsf2, endFn] = data;
            return {
                type: "prev_period",
                extraArgs: {
                    dateField: field2,
                    metricField: field1
                }
            };
        } },
    {"name": "if$string$1", "symbols": [{"literal":"I"}, {"literal":"F"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "if", "symbols": ["if$string$1", "_", "condition", "_", {"literal":","}, "_", "expression", "_", {"literal":","}, "_", "expression", "_", {"literal":")"}], "postprocess":  function (data) {
            const [
                start,
                startWSCond, condition, endWSCond,
                sep1,
                startWSTrueExp, trueExpression, endWSTrueExp,
                sep2,
                startWSFalseExp, falseExpression, endWSFalseExp,
                end
            ] = data;
            return {
                type: "if",
                extraArgs: {
                    condition,
                    trueExpression,
                    falseExpression
                }
            };
        } },
    {"name": "ifnull$string$1", "symbols": [{"literal":"I"}, {"literal":"F"}, {"literal":"N"}, {"literal":"U"}, {"literal":"L"}, {"literal":"L"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "ifnull", "symbols": ["ifnull$string$1", "_", "main", "_", {"literal":","}, "_", "main", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, expression1, ewsf1, separator, swsf2, expression2, ewsf2, endFn] = data;
            return {
                type: "ifnull",
                extraArgs: {
                    expression1: expression1,
                    expression2: expression2,
                    data2: data
                }
            };
        } },
    {"name": "mod$string$1", "symbols": [{"literal":"M"}, {"literal":"O"}, {"literal":"D"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "mod", "symbols": ["mod$string$1", "main", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, numerator, separator, denominator, end] = data;
            return {
                type: "mod",
                extraArgs: {
                    numeratorExpression: numerator,
                    denominatorExpression: denominator,
                }
            };
        } },
    {"name": "max_value$string$1", "symbols": [{"literal":"M"}, {"literal":"A"}, {"literal":"X"}, {"literal":"_"}, {"literal":"V"}, {"literal":"A"}, {"literal":"L"}, {"literal":"U"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "max_value$ebnf$1$subexpression$1$ebnf$1", "symbols": []},
    {"name": "max_value$ebnf$1$subexpression$1$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "main"]},
    {"name": "max_value$ebnf$1$subexpression$1$ebnf$1", "symbols": ["max_value$ebnf$1$subexpression$1$ebnf$1", "max_value$ebnf$1$subexpression$1$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "max_value$ebnf$1$subexpression$1", "symbols": ["max_value$ebnf$1$subexpression$1$ebnf$1"]},
    {"name": "max_value$ebnf$1", "symbols": ["max_value$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "max_value$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "max_value", "symbols": ["max_value$string$1", "main", {"literal":","}, "main", "max_value$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, firstOperation, separator, secondOperation, extraOperations, end] = data;
            const operations = [firstOperation, secondOperation];
            if (extraOperations && extraOperations[0]) {
                operations.push(...extraOperations[0].map(item => item && item[1]).filter(item => ![null, undefined].includes(item)));
            }
            return {
                type: 'max_value',
                extraArgs: {
                    operationsArray: operations
                }
            };
        } },
    {"name": "min_value$string$1", "symbols": [{"literal":"M"}, {"literal":"I"}, {"literal":"N"}, {"literal":"_"}, {"literal":"V"}, {"literal":"A"}, {"literal":"L"}, {"literal":"U"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "min_value$ebnf$1$subexpression$1$ebnf$1", "symbols": []},
    {"name": "min_value$ebnf$1$subexpression$1$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "main"]},
    {"name": "min_value$ebnf$1$subexpression$1$ebnf$1", "symbols": ["min_value$ebnf$1$subexpression$1$ebnf$1", "min_value$ebnf$1$subexpression$1$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "min_value$ebnf$1$subexpression$1", "symbols": ["min_value$ebnf$1$subexpression$1$ebnf$1"]},
    {"name": "min_value$ebnf$1", "symbols": ["min_value$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "min_value$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "min_value", "symbols": ["min_value$string$1", "main", {"literal":","}, "main", "min_value$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, firstOperation, separator, secondOperation, extraOperations, end] = data;
            const operations = [firstOperation, secondOperation];
            if (extraOperations && extraOperations[0]) {
                operations.push(...extraOperations[0].map(item => item && item[1]).filter(item => ![null, undefined].includes(item)));
            }
            return {
                type: 'min_value',
                extraArgs: {
                    operationsArray: operations
                }
            };
        } },
    {"name": "power$string$1", "symbols": [{"literal":"P"}, {"literal":"O"}, {"literal":"W"}, {"literal":"E"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "power", "symbols": ["power$string$1", "main", {"literal":","}, "_", "int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, separator, ws1, power, ws2, end] = data;
            return {
                type: 'power',
                extraArgs: {
                    valueExpression: value,
                    power
                }
            };
        } },
    {"name": "sign$string$1", "symbols": [{"literal":"S"}, {"literal":"I"}, {"literal":"G"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "sign", "symbols": ["sign$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, end] = data;
            return {
                type: 'sign',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "sqrt$string$1", "symbols": [{"literal":"S"}, {"literal":"Q"}, {"literal":"R"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "sqrt", "symbols": ["sqrt$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, end] = data;
            return {
                type: 'sqrt',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "square$string$1", "symbols": [{"literal":"S"}, {"literal":"Q"}, {"literal":"U"}, {"literal":"A"}, {"literal":"R"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "square", "symbols": ["square$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, end] = data;
            return {
                type: 'square',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "exp$string$1", "symbols": [{"literal":"E"}, {"literal":"X"}, {"literal":"P"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "exp", "symbols": ["exp$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, end] = data;
            return {
                type: 'exp',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "abs$string$1", "symbols": [{"literal":"A"}, {"literal":"B"}, {"literal":"S"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "abs", "symbols": ["abs$string$1", "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, end] = data;
            return {
                type: 'abs',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "round$string$1", "symbols": [{"literal":"R"}, {"literal":"O"}, {"literal":"U"}, {"literal":"N"}, {"literal":"D"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "round$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "int", "_"]},
    {"name": "round$ebnf$1", "symbols": ["round$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "round$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "round", "symbols": ["round$string$1", "main", "round$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, precission, end] = data;
            return {
                type: 'round',
                extraArgs: {
                    valueExpression: value,
                    precission: precission && precission[2]
                }
            };
        } },
    {"name": "floor$string$1", "symbols": [{"literal":"F"}, {"literal":"L"}, {"literal":"O"}, {"literal":"O"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "floor$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "int", "_"]},
    {"name": "floor$ebnf$1", "symbols": ["floor$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "floor$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "floor", "symbols": ["floor$string$1", "main", "floor$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, precission, end] = data;
            const evaluateOperator = (operation) => {
                if (["add", "subtract", "product", "divide"].includes(operation.type)) {
                    return {
                        type: "operation",
                        operation: operation
                    };
                }
                else {
                    return operation;
                }
            };
            return {
                type: 'floor',
                extraArgs: {
                    valueExpression: evaluateOperator(value),
                    precission: precission && precission[2]
                }
            };
        } },
    {"name": "ceiling$string$1", "symbols": [{"literal":"C"}, {"literal":"E"}, {"literal":"I"}, {"literal":"L"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "ceiling$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "int", "_"]},
    {"name": "ceiling$ebnf$1", "symbols": ["ceiling$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "ceiling$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "ceiling", "symbols": ["ceiling$string$1", "main", "ceiling$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, precission, end] = data;
            const evaluateOperator = (operation) => {
                if (["add", "subtract", "product", "divide"].includes(operation.type)) {
                    return {
                        type: "operation",
                        operation: operation
                    };
                }
                else {
                    return operation;
                }
            };
            return {
                type: 'ceiling',
                extraArgs: {
                    valueExpression: evaluateOperator(value),
                    precission: precission && precission[2]
                }
            };
        } },
    {"name": "div$string$1", "symbols": [{"literal":"D"}, {"literal":"I"}, {"literal":"V"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "div", "symbols": ["div$string$1", "main", {"literal":","}, "main", {"literal":")"}], "postprocess":  function (data) {
            const [start, numerator, separator, denominator, end] = data;
            const evaluateOperator = (operation) => {
                if (["add", "subtract", "product", "divide"].includes(operation.type)) {
                    return {
                        type: "operation",
                        operation: operation
                    };
                }
                else {
                    return operation;
                }
            };
            return {
                type: 'divide',
                extraArgs: {
                    operator1: evaluateOperator(numerator),
                    operator2: evaluateOperator(denominator)
                }
            };
        } },
    {"name": "ln$string$1", "symbols": [{"literal":"L"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "ln", "symbols": ["ln$string$1", "main", {"literal":")"}], "postprocess":  function(data) {
            const [start, value, end] = data;
            return {
                type: 'ln',
                extraArgs: {
                    valueExpression: value
                }
            };
        } },
    {"name": "log$string$1", "symbols": [{"literal":"L"}, {"literal":"O"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "log$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "int", "_"]},
    {"name": "log$ebnf$1", "symbols": ["log$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "log$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "log", "symbols": ["log$string$1", "main", "log$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [start, value, base, end] = data;
        
            return {
                type: 'log',
                extraArgs: {
                    valueExpression: value,
                    base: base && base[2]
                }
            };
        } },
    {"name": "concat$string$1", "symbols": [{"literal":"C"}, {"literal":"O"}, {"literal":"N"}, {"literal":"C"}, {"literal":"A"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "concat$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "main"]},
    {"name": "concat$ebnf$1", "symbols": ["concat$ebnf$1$subexpression$1"]},
    {"name": "concat$ebnf$1$subexpression$2", "symbols": [{"literal":","}, "main"]},
    {"name": "concat$ebnf$1", "symbols": ["concat$ebnf$1", "concat$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "concat", "symbols": ["concat$string$1", "main", "concat$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            return {
                type: "concat",
                extraArgs: {
                    stringsArray: [data[1], ...data[2].map(item => item[1])]
                }
            };
        } },
    {"name": "contains$string$1", "symbols": [{"literal":"C"}, {"literal":"O"}, {"literal":"N"}, {"literal":"T"}, {"literal":"A"}, {"literal":"I"}, {"literal":"N"}, {"literal":"S"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "contains", "symbols": ["contains$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, string, ewsf2, endFn] = data;
            return {
                type: "contains",
                extraArgs: {
                    type: "field",
                    field,
                    string
                }
            };
        }},
    {"name": "ends_with$string$1", "symbols": [{"literal":"E"}, {"literal":"N"}, {"literal":"D"}, {"literal":"S"}, {"literal":"_"}, {"literal":"W"}, {"literal":"I"}, {"literal":"T"}, {"literal":"H"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "ends_with", "symbols": ["ends_with$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, string, ewsf2, endFn] = data;
            return {
                type: "ends_with",
                extraArgs: {
                    type: "field",
                    field,
                    string
                }
            };
        }},
    {"name": "starts_with$string$1", "symbols": [{"literal":"S"}, {"literal":"T"}, {"literal":"A"}, {"literal":"R"}, {"literal":"T"}, {"literal":"S"}, {"literal":"_"}, {"literal":"W"}, {"literal":"I"}, {"literal":"T"}, {"literal":"H"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "starts_with", "symbols": ["starts_with$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, string, ewsf2, endFn] = data;
            return {
                type: "starts_with",
                extraArgs: {
                    type: "field",
                    field,
                    string
                }
            };
        }},
    {"name": "find$string$1", "symbols": [{"literal":"F"}, {"literal":"I"}, {"literal":"N"}, {"literal":"D"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "find$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "number_int", "_"]},
    {"name": "find$ebnf$1", "symbols": ["find$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "find$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "find", "symbols": ["find$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", "find$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, string, ewsf2, optionalStart] = data;
            return {
                type: "find",
                extraArgs: {
                    type: "field",
                    field,
                    string,
                    start: optionalStart ? optionalStart[2] : undefined
                }
            };
        } },
    {"name": "findnth$string$1", "symbols": [{"literal":"F"}, {"literal":"I"}, {"literal":"N"}, {"literal":"D"}, {"literal":"N"}, {"literal":"T"}, {"literal":"H"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "findnth", "symbols": ["findnth$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator1, swsf2, string, ewsf2, separator2, swsf3, occurrence, ewsf3, endFn] = data;
            return {
                type: "findnth",
                extraArgs: {
                    type: "field",
                    field,
                    string,
                    occurrence
                }
            };
        } },
    {"name": "left$string$1", "symbols": [{"literal":"L"}, {"literal":"E"}, {"literal":"F"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "left", "symbols": ["left$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator1, swsf2, number, ewsf2, endFn] = data;
            return {
                type: "left",
                extraArgs: {
                    type: "field",
                    field,
                    number
                }
            };
        } },
    {"name": "len$string$1", "symbols": [{"literal":"L"}, {"literal":"E"}, {"literal":"N"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "len", "symbols": ["len$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "len",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "capitalize$string$1", "symbols": [{"literal":"C"}, {"literal":"A"}, {"literal":"P"}, {"literal":"I"}, {"literal":"T"}, {"literal":"A"}, {"literal":"L"}, {"literal":"I"}, {"literal":"Z"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "capitalize", "symbols": ["capitalize$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "capitalize",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "lower$string$1", "symbols": [{"literal":"L"}, {"literal":"O"}, {"literal":"W"}, {"literal":"E"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "lower", "symbols": ["lower$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "lower",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "ltrim$string$1", "symbols": [{"literal":"L"}, {"literal":"T"}, {"literal":"R"}, {"literal":"I"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "ltrim", "symbols": ["ltrim$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "ltrim",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "substring$string$1", "symbols": [{"literal":"S"}, {"literal":"U"}, {"literal":"B"}, {"literal":"S"}, {"literal":"T"}, {"literal":"R"}, {"literal":"I"}, {"literal":"N"}, {"literal":"G"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "substring$ebnf$1$subexpression$1", "symbols": [{"literal":","}, "_", "number_int", "_"]},
    {"name": "substring$ebnf$1", "symbols": ["substring$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "substring$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "substring", "symbols": ["substring$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", "substring$ebnf$1", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator, swsf2, start, ewsf2, optionalLength] = data;
            return {
                type: "substring",
                extraArgs: {
                    type: "field",
                    field,
                    start,
                    length: optionalLength ? optionalLength[2] : undefined
                }
            };
        } },
    {"name": "replace$string$1", "symbols": [{"literal":"R"}, {"literal":"E"}, {"literal":"P"}, {"literal":"L"}, {"literal":"A"}, {"literal":"C"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "replace", "symbols": ["replace$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":","}, "_", "string", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator1, swsf2, substring, ewsf2, separator2, swsf3, replacement, ewsf3, endFn] = data;
            return {
                type: "replace",
                extraArgs: {
                    type: "field",
                    field,
                    substring,
                    replacement
                }
            };
        } },
    {"name": "right$string$1", "symbols": [{"literal":"R"}, {"literal":"I"}, {"literal":"G"}, {"literal":"H"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "right", "symbols": ["right$string$1", "_", "field", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator1, swsf2, number, ewsf2, endFn] = data;
            return {
                type: "right",
                extraArgs: {
                    type: "field",
                    field,
                    number
                }
            };
        } },
    {"name": "rtrim$string$1", "symbols": [{"literal":"R"}, {"literal":"T"}, {"literal":"R"}, {"literal":"I"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "rtrim", "symbols": ["rtrim$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "rtrim",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "space$string$1", "symbols": [{"literal":"S"}, {"literal":"P"}, {"literal":"A"}, {"literal":"C"}, {"literal":"E"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "space", "symbols": ["space$string$1", "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, number, ewsf1, endFn] = data;
            return {
                type: "space",
                extraArgs: {
                    number
                }
            };
        } },
    {"name": "split$string$1", "symbols": [{"literal":"S"}, {"literal":"P"}, {"literal":"L"}, {"literal":"I"}, {"literal":"T"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "split", "symbols": ["split$string$1", "_", "field", "_", {"literal":","}, "_", "string", "_", {"literal":","}, "_", "number_int", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, separator1, swsf2, delimiter, ewsf2, separator2, swsf3, tokenNumber, ewsf3, endFn] = data;
            return {
                type: "split",
                extraArgs: {
                    type: "field",
                    field,
                    delimiter,
                    tokenNumber
                }
            };
        } },
    {"name": "trim$string$1", "symbols": [{"literal":"T"}, {"literal":"R"}, {"literal":"I"}, {"literal":"M"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "trim", "symbols": ["trim$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "trim",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "upper$string$1", "symbols": [{"literal":"U"}, {"literal":"P"}, {"literal":"P"}, {"literal":"E"}, {"literal":"R"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "upper", "symbols": ["upper$string$1", "_", "field", "_", {"literal":")"}], "postprocess":  function (data) {
            const [startFn, swsf1, field, ewsf1, endFn] = data;
            return {
                type: "upper",
                extraArgs: {
                    type: "field",
                    field
                }
            };
        } },
    {"name": "condition", "symbols": ["expression", "_", "comparator", "_", "expression"], "postprocess":  function (data) {
            const [expression1, ws1, comparator, ws2, expression2] = data;
            return {
                expression1,
                comparator,
                expression2
            };
        } },
    {"name": "comparator", "symbols": [{"literal":"="}]},
    {"name": "comparator$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$1"]},
    {"name": "comparator", "symbols": [{"literal":"<"}]},
    {"name": "comparator$string$2", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$2"]},
    {"name": "comparator", "symbols": [{"literal":">"}]},
    {"name": "comparator$string$3", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$3"], "postprocess": id},
    {"name": "field$ebnf$1", "symbols": ["datafield_alias"], "postprocess": id},
    {"name": "field$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "field", "symbols": [{"literal":"{"}, "_", "query", {"literal":"."}, "position", "field$ebnf$1", "_", {"literal":"}"}], "postprocess":  function (data) {
            const [start, swsf, query, dot, position, datasetAlias, ewsf, end] = data;
            return {
                query,
                position,
                datasetAlias,
            };
        } },
    {"name": "query$ebnf$1", "symbols": []},
    {"name": "query$ebnf$1", "symbols": ["query$ebnf$1", "digit"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "query", "symbols": [{"literal":"Q"}, "digit_exclude_zero", "query$ebnf$1"], "postprocess":  function (data) {
            const [prefix, fristDigit, restDigits] = data;
            return prefix + fristDigit + restDigits.join("");
        } },
    {"name": "query$string$1", "symbols": [{"literal":"C"}, {"literal":"F"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "query", "symbols": ["query$string$1"], "postprocess": () => "CF"},
    {"name": "position$ebnf$1", "symbols": []},
    {"name": "position$ebnf$1", "symbols": ["position$ebnf$1", "digit"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "position", "symbols": ["digit_exclude_zero", "position$ebnf$1"], "postprocess":  function (data) {
            const [firstDigit, restDigits] = data;
            return parseInt(firstDigit + restDigits.join(""));
        } },
    {"name": "datafield_alias", "symbols": ["__", "word"], "postprocess":  function (data) {
            const [startWS, word] = data;
            return word;
        } },
    {"name": "string$subexpression$1", "symbols": [{"literal":"\""}]},
    {"name": "string$subexpression$1", "symbols": [{"literal":"'"}]},
    {"name": "string$subexpression$2", "symbols": [{"literal":"\""}]},
    {"name": "string$subexpression$2", "symbols": [{"literal":"'"}]},
    {"name": "string", "symbols": ["string$subexpression$1", "spword", "string$subexpression$2"], "postprocess":  function (data) {
            const [s1, string, s2] = data;
            return string;
        } },
    {"name": "word$ebnf$1", "symbols": ["char"]},
    {"name": "word$ebnf$1", "symbols": ["word$ebnf$1", "char"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "word", "symbols": ["word$ebnf$1"], "postprocess": ([chars]) => chars.join("")},
    {"name": "spword$ebnf$1", "symbols": ["schar"]},
    {"name": "spword$ebnf$1", "symbols": ["spword$ebnf$1", "schar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "spword", "symbols": ["spword$ebnf$1"], "postprocess": ([chars]) => chars.join("")},
    {"name": "schar", "symbols": ["upper_char"]},
    {"name": "schar", "symbols": ["lower_char"]},
    {"name": "schar", "symbols": ["ws_char"]},
    {"name": "schar", "symbols": ["special_char"]},
    {"name": "schar", "symbols": ["digit"]},
    {"name": "schar", "symbols": ["operator_char"]},
    {"name": "char", "symbols": ["upper_char"]},
    {"name": "char", "symbols": ["lower_char"]},
    {"name": "char", "symbols": ["ws_char"]},
    {"name": "char", "symbols": ["special_char"]},
    {"name": "char", "symbols": ["digit"]},
    {"name": "upper_char", "symbols": [/[A-Z]/], "postprocess": id},
    {"name": "lower_char", "symbols": [/[a-z]/], "postprocess": id},
    {"name": "special_char", "symbols": [/[#_,.ºª@!:¡?¿·#€$~%&¬^()]/], "postprocess": id},
    {"name": "operator_char", "symbols": [/[-+*/]/], "postprocess": id},
    {"name": "ws_char", "symbols": [/[\s]/], "postprocess": id},
    {"name": "any_char", "symbols": [/./], "postprocess": id},
    {"name": "number$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "number$ebnf$1$subexpression$1", "symbols": [{"literal":"+"}]},
    {"name": "number$ebnf$1", "symbols": ["number$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "number$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "number", "symbols": ["number$ebnf$1", "float"], "postprocess":  function (data) {
            const [symbol, float] = data;
            return parseFloat(symbol ? symbol[0] + float : float);
        } },
    {"name": "number_int$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "number_int$ebnf$1$subexpression$1", "symbols": [{"literal":"+"}]},
    {"name": "number_int$ebnf$1", "symbols": ["number_int$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "number_int$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "number_int", "symbols": ["number_int$ebnf$1", "int"], "postprocess":  function (data) {
            const [symbol, int] = data;
            return parseInt(symbol ? symbol[0] + int : int);
        } },
    {"name": "float$ebnf$1$subexpression$1", "symbols": [{"literal":"."}, "int"]},
    {"name": "float$ebnf$1", "symbols": ["float$ebnf$1$subexpression$1"], "postprocess": id},
    {"name": "float$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "float", "symbols": ["int", "float$ebnf$1"], "postprocess":  function (data) {
            const [int, decimal] = data;
            return parseFloat(int + (decimal ? decimal.join("") : ""));
        } },
    {"name": "int$ebnf$1", "symbols": ["digit"]},
    {"name": "int$ebnf$1", "symbols": ["int$ebnf$1", "digit"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "int", "symbols": ["int$ebnf$1"], "postprocess":  function (data) {
            const [digits] = data;
            return parseInt(digits.join(""));
        } },
    {"name": "digit", "symbols": [/[0-9]/], "postprocess": id},
    {"name": "digit_exclude_zero", "symbols": [/[1-9]/], "postprocess": id},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"]},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"]},
    {"name": "wschar", "symbols": [/[ \t\n\v\f]/], "postprocess": () => null}
]
  , ParserStart: "main"
};

export default grammar;
