import { List, Map, fromJS } from 'immutable';

// Libs
import Auth from './auth-lib';

/**
 * Session Actions
 */
import {
    // Initialize Session Reducer
    REMOVE_SESSION_DATA,
    // From Local Storage
    SET_IS_LOGGED_IN, GET_USER_FROM_LOCAL_STORAGE, GET_MASQUERADE_USER_FROM_LOCAL_STORAGE, GET_CURRENT_ORGANIZATION,
    // User
    UPDATE_USER_TO_REDUX,
    // User - Default organization
    SET_DEFAULT_ORGANIZATION_REQUEST, SET_DEFAULT_ORGANIZATION_SUCCESS, SET_DEFAULT_ORGANIZATION_ERROR,
    // User Profile
    SAVE_PROFILE_REQUEST, SAVE_PROFILE_SUCCESS, SAVE_PROFILE_ERROR,
    // User Permissions
    GET_USER_PERMISSIONS_REQUEST, GET_USER_PERMISSIONS_SUCCESS, GET_USER_PERMISSIONS_ERROR,
    // User - Switch content language
    SWITCH_CONTENT_LANGUAGE_SUCCESS, SWITCH_CONTENT_LANGUAGE_ERROR,
    // Organization stats
    GET_ORGANIZATION_STATS_REQUEST, GET_ORGANIZATION_STATS_SUCCESS, GET_ORGANIZATION_STATS_ERROR, NEW_ORGANIZATION_STATS,
    // Organization - Switch Organization
    SWITCH_ORGANIZATION_SUCCESS, SWITCH_ORGANIZATION_ERROR,
    // Masquerade user
    MASQUERADE_USER_REQUEST, MASQUERADE_USER_SUCCESS, MASQUERADE_USER_ERROR, RECOVER_MASQUERADE_USER,
    // Signin
    SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNIN_CLEAN_ERROR,
    // Signout
    SIGNOUT
} from './session-actions';

// Organizations Actions
import { ORGANIZATION_CREATE_SUCCESS, ORGANIZATION_UPDATE_SUCCESS } from "@biuwer/redux/src/system/organizations/organizations-actions";

// Orders Actions
import { ORDER_CREATE_SUCCESS } from "@biuwer/redux/src/billing/orders/orders-actions";

const initialState = Map({});

function session(state = initialState, action) {
    let newState, updatedPayload, updatedUserPayload, organizations, organization;
    switch (action.type) {

        // Initialize Session Reducer
        case REMOVE_SESSION_DATA:
            return initialState;

        // From Local Storage
        case SET_IS_LOGGED_IN:
            return state.merge({
                isLoggedIn: action.isLoggedIn
            });
        case GET_USER_FROM_LOCAL_STORAGE:
            return state.merge({
                user: fromJS(action.user)
            });
        case GET_MASQUERADE_USER_FROM_LOCAL_STORAGE:
            return state.merge({
                masqueradeUser: Map({
                    masquerading: action.masquerading,
                    masqueradePayload: fromJS(action.masqueradePayload)
                })
            });
        case GET_CURRENT_ORGANIZATION:
            return state.merge({ currentOrganization: fromJS(action.payload) });

        // User, change default organization and profile
        case UPDATE_USER_TO_REDUX:
            return state
                .remove('user')
                .merge({
                    user: fromJS(action.user)
                });
        case SET_DEFAULT_ORGANIZATION_REQUEST:
        case SAVE_PROFILE_REQUEST:
            return state.merge({
                isFetching: true,
                error: false
            });
        case SET_DEFAULT_ORGANIZATION_SUCCESS:
        case SAVE_PROFILE_SUCCESS:
            return state.merge({
                user: fromJS(action.payload),
                isFetching: false,
                updated: true
            });
        case SET_DEFAULT_ORGANIZATION_ERROR:
        case SAVE_PROFILE_ERROR:
            return state.merge({
                isFetching: false,
                error: action.error
            });

        // User Permissions
        case GET_USER_PERMISSIONS_REQUEST:
            return state
                .remove('permissions')
                .merge({
                    permissions: fromJS({
                        isFetching: true,
                        error: false
                    })
            });
        case GET_USER_PERMISSIONS_SUCCESS:
            return state.merge({
                permissions: fromJS({
                    isFetching: false,
                    payload: action.permissions
                })
            });
        case GET_USER_PERMISSIONS_ERROR:
            return state.merge({
                permissions: fromJS({
                    isFetching: false,
                    error: true,
                    errorMessage: action.errorMessage
                })
            });

        // User content language
        case SWITCH_CONTENT_LANGUAGE_SUCCESS:
            return state.merge({
                currentContentLanguage: action.payload
            });
        case SWITCH_CONTENT_LANGUAGE_ERROR:
            return state.merge({
                currentContentLanguage: ""
            });

        // Masquerade User
        case MASQUERADE_USER_REQUEST:
            return state
                .remove('masqueradeUser')
                .merge({
                    masqueradeUser: Map({
                        isFetching: true,
                        error: false
                    })
            });
        case MASQUERADE_USER_SUCCESS:
            return state.merge({
                masqueradeUser: Map({
                    isFetching: false,
                    error: false,
                    masquerading: true,
                    masqueradePayload: fromJS(action.masqueradePayload)
                })
            });
        case MASQUERADE_USER_ERROR:
            return state.merge({
                masqueradeUser: Map({
                    isFetching: false,
                    error: true,
                    errorMessage: action.errorMessage
                })
            });
        case RECOVER_MASQUERADE_USER:
            return state.remove('masqueradeUser');

        // Switch Organization
        case SWITCH_ORGANIZATION_SUCCESS:
            return state.merge({ currentOrganization: fromJS(action.payload) });
        case SWITCH_ORGANIZATION_ERROR:
            return state.merge({
                errorSwitchOrganization: action.error
            });

        // Listen to ORGANIZATION_CREATE_SUCCESS, updating user organizations in session's reducer after creating a new organization
        case ORGANIZATION_CREATE_SUCCESS:
            if(List.isList(state.getIn(['user', 'organizations'])) && action.payload) {
                organization = {
                    _id: action.payload._id,
                    name: action.payload.name,
                    status: action.payload.status
                };
                organizations = state.getIn(['user', 'organizations']).push(fromJS({organization, status: 'active'}));
                updatedPayload = state.setIn(['user', 'organizations'], organizations).get('user');
            } else {
                updatedPayload = state.get('user');
            }
            Auth.setUser(updatedPayload.toJS());
            newState = state.merge({
                user: updatedPayload
            });
            return newState;
        case ORGANIZATION_UPDATE_SUCCESS:
            newState = state;
            if (List.isList(state.getIn(['user', 'organizations'])) && action.payload) {
                organizations = state.getIn(['user', 'organizations']);
                organization = {
                    _id: action.payload._id,
                    name: action.payload.name,
                    settings: action.payload.settings,
                    status: action.payload.status,
                    trial: action.payload.trial
                };
                let organizationIndex = organizations.findIndex(org => org.toJS().organization?._id === action.payload._id);
                if (organizationIndex >= 0) {
                    organizations = organizations.mergeIn([organizationIndex, "organization"], fromJS(organization));
                } else {
                    organizations = state.getIn(['user', 'organizations']).push(fromJS({organization, status: 'active'}));
                }
                updatedUserPayload = state.setIn(['user', 'organizations'], organizations).get('user');
            } else {
                updatedUserPayload = state.get('user');
            }
            Auth.setUser(updatedUserPayload.toJS());
            newState = newState
                .remove('user')
                .merge({
                    user: updatedUserPayload
                });
            if (state.getIn(['currentOrganization', '_id']) && String(state.getIn(['currentOrganization', '_id'])) === String(action.payload._id)) {
                updatedPayload = fromJS({
                    ...state.get('currentOrganization').toJS(),
                    name: action.payload.name,
                    settings: action.payload.settings
                });
                newState = newState
                    .remove('currentOrganization')
                    .merge({
                        currentOrganization: updatedPayload
                    });
            }
            return newState;
        case ORDER_CREATE_SUCCESS:
            newState = state;
            const updatedOrganization = action.payload.organization;
            if (List.isList(state.getIn(['user', 'organizations'])) && updatedOrganization) {
                organizations = state.getIn(['user', 'organizations']);
                organization = {
                    _id: updatedOrganization._id,
                    name: updatedOrganization.name,
                    settings: updatedOrganization.settings,
                    status: updatedOrganization.status,
                    accumulated_features: updatedOrganization.accumulated_features,
                    apply_feature_wall: updatedOrganization.apply_feature_wall,
                    automatic_billing: updatedOrganization.automatic_billing
                };
                let organizationIndex = organizations.findIndex(org => org.toJS().organization._id === updatedOrganization._id);
                if (organizationIndex >= 0) {
                    organizations = organizations.mergeIn([organizationIndex], fromJS({ organization: organization }));
                } else {
                    organizations = state.getIn(['user', 'organizations']).push(fromJS({organization, status: 'active'}));
                }
                updatedUserPayload = state.setIn(['user', 'organizations'], organizations).get('user');
            } else {
                updatedUserPayload = state.get('user');
            }
            Auth.setUser(updatedUserPayload.toJS());
            newState = newState
                .remove('user')
                .merge({
                    user: updatedUserPayload
                });
            if (state.getIn(['currentOrganization', '_id']) && String(state.getIn(['currentOrganization', '_id'])) === String(updatedOrganization._id)) {
                updatedPayload = fromJS({
                    ...state.get('currentOrganization').toJS(),
                    name: updatedOrganization.name,
                    status: updatedOrganization.status,
                    trial: updatedOrganization.trial,
                    accumulated_features: updatedOrganization.accumulated_features,
                    apply_feature_wall: updatedOrganization.apply_feature_wall,
                    automatic_billing: updatedOrganization.automatic_billing
                });
                newState = newState
                    .remove('currentOrganization')
                    .merge({
                        currentOrganization: updatedPayload
                    });
            }
            return newState;

        // Organization Stats
        case GET_ORGANIZATION_STATS_REQUEST:
            return state
                .remove('organizationStats')
                .merge({
                    organizationStats: Map({
                        isFetching: true,
                        error: false
                    })
                });
        case GET_ORGANIZATION_STATS_SUCCESS:
            return state.merge({
                organizationStats: Map({
                    isFetching: false,
                    error: false,
                    stats: fromJS(action.organizationStats)
                })
            });
        case GET_ORGANIZATION_STATS_ERROR:
            return state.merge({
                organizationStats: Map({
                    isFetching: false,
                    error: true,
                    errorMessage: action.errorMessage
                })
            });
        case NEW_ORGANIZATION_STATS:
            return state.merge({
                organizationStats: Map({
                    stats: fromJS(action.organizationStats)
                })
            });

        // Signin
        case SIGNIN_SUCCESS:
            return state.merge({
                user: fromJS(action.user),
                isLoggedIn: true,
                currentOrganization: fromJS(action.currentOrganization)
            });
        case SIGNIN_ERROR:
            return state.merge({
                errorSignin: fromJS(action.error)
            });
        case SIGNIN_CLEAN_ERROR:
            return state.merge({
                errorSignin: ''
            });

        // Signou
        case SIGNOUT:
            return state
                .remove('user')
                .remove('masqueradeUser')
                .merge({
                    isLoggedIn: false
                });

        default:
            return state;
    }
}

export default session;
