import html2canvas from "@cantoo/html2canvas"
import FileSaver from "file-saver"

import { CARD_FLOAT_AREA_CLASS, CARD_SCREEN_BODY_ID, DRAWER_MENU_BUTTON_ID, FILTERS_AREA_CLASS, PAGE_BODY_ID, PAGE_HEADER_ID, PAGE_HEADER_UTILS_ID, PAGE_HEADER_WRAPPER_ID } from "./content-constants"

const CSS_PPI = 96
const MM_PER_INCH = 25.4
const CM_PER_INCH = MM_PER_INCH / 10

const A4_PORTRAIT_SIZE_IN_CM = {
    width: 21,
    height: 29.7
}

const A3_PORTRAIT_SIZE_IN_CM = {
    width: 29.7,
    height: 42
}

const A2_PORTRAIT_SIZE_IN_CM = {
    width: 42,
    height: 59.4
}

const A1_PORTRAIT_SIZE_IN_CM = {
    width: 59.4,
    height: 84.1
}

/**
 * Convert the given pixels measure to centimeters, taking care of pixel density
 * @param {Number} px
 * @param {Number} devicePixelRatio ratio given from window
 * @returns {Number}
 */
export function convertPixelsToCentimeters(px, devicePixelRatio = 1) {
    return Number((px * CM_PER_INCH / (CSS_PPI * devicePixelRatio)).toFixed(2))
}

/**
 * Convert centimeters measure to pixels, taking care of pixel density
 * @param {Number} cm
 * @param {Number} devicePixelRatio
 * @returns {Number}
 */
export function convertCentimetersToPixels(cm, devicePixelRatio = 1) {
    return Number(((cm * CSS_PPI * devicePixelRatio) / CM_PER_INCH).toFixed(2))
}

/**
 * Convert the given pixels measure to milimeters, taking care of pixel density
 * @param {Number} px
 * @param {Number} devicePixelRatio ratio given from window
 * @returns {Number}
 */
export function convertPixelsToMilimeters(px, devicePixelRatio = 1) {
    return Number((px * MM_PER_INCH / (CSS_PPI * devicePixelRatio)).toFixed(2))
}

/**
 * Convert milimeters measure to pixels, taking care of pixel density
 * @param {Number} mm
 * @param {Number} devicePixelRatio
 * @returns {Number}
 */
export function convertMilimetersToPixels(mm, devicePixelRatio = 1) {
    return Number(((mm * CSS_PPI * devicePixelRatio) / MM_PER_INCH).toFixed(2))
}

/**
 *
 * @param {Blob} blob
 * @param {String} name
 * @param {String} extension
 */
export function saveFile(blob, name, extension) {
    FileSaver.saveAs(blob, `${name}.${extension}`)
}

/**
 * Generate a canvas and a image blob from given HTML element
 * @param {HTMLElement} element
 * @param {function | null} onClone function to process HTML properties before generate canvas
 * @returns {Promise<{ canvas: HTMLCanvasElement, blob: Blob, width: Number, height: Number }>}
 */
export async function printElement(element, onClone = null) {
    const canvas = await html2canvas(element, {
        onclone: onClone
    })

    const blob = await getBlobFromCanvas(canvas)

    const bitmap = await createImageBitmap(blob)
    const width = bitmap.width
    const height = bitmap.height
    bitmap.close()

    return { canvas, blob, width, height }
}

export function getOptimalPrintSizeAndOrientation(width, height, margins, devicePixelRatio = 1) {
    const A4InPixels = getPrintPageSizeInPixels("A4", "portrait", devicePixelRatio)

    const totalWidth = width + margins[1] + margins[3]

    if (totalWidth <= A4InPixels.width) {
        return { size: "A4", orientation: "portrait" }
    }
    if (totalWidth <= A4InPixels.height) {
        return { size: "A4", orientation: "landscape" }
    }

    const A3InPixels = getPrintPageSizeInPixels("A3", "portrait", devicePixelRatio)

    if (totalWidth <= A3InPixels.width) {
        return { size: "A3", orientation: "portrait" }
    }
    if (totalWidth <= A3InPixels.height) {
        return { size: "A3", orientation: "landscape" }
    }

    const A2InPixels = getPrintPageSizeInPixels("A2", "portrait", devicePixelRatio)

    if (totalWidth <= A2InPixels.width) {
        return { size: "A2", orientation: "portrait" }
    }
    if (totalWidth <= A2InPixels.height) {
        return { size: "A2", orientation: "landscape" }
    }

    const A1InPixels = getPrintPageSizeInPixels("A1", "portrait", devicePixelRatio)

    if (totalWidth <= A1InPixels.width) {
        return { size: "A1", orientation: "portrait" }
    }
    if (totalWidth <= A1InPixels.height) {
        return { size: "A1", orientation: "landscape" }
    }
}

/**
 *
 * @param {"A4" | "A3" | "A2" | "A1"} size
 * @param {"portrait" | "landscape"} orientation
 * @param {Number} devicePixelRatio
 * @returns {{ width: Number, height: Number }}
 */
export function getPrintPageSizeInPixels(size = "A4", orientation = "portrait", devicePixelRatio = 1) {
    let pageSize = A4_PORTRAIT_SIZE_IN_CM
    if (size === "A3") pageSize = A3_PORTRAIT_SIZE_IN_CM
    if (size === "A2") pageSize = A2_PORTRAIT_SIZE_IN_CM
    if (size === "A1") pageSize = A1_PORTRAIT_SIZE_IN_CM

    if (orientation === "landscape") {
        pageSize = {
            width: pageSize.height,
            height: pageSize.width
        }
    }

    return {
        width: convertCentimetersToPixels(pageSize.width, devicePixelRatio),
        height: convertCentimetersToPixels(pageSize.height, devicePixelRatio)
    }
}

/**
 *
 * @param {Blob} blob
 * @returns {Promise<String>}
 */
export async function getDataURLFromBlob(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => reject(reader.error)
        reader.onabort = () => reject(new Error("Read aborted"))
        reader.readAsDataURL(blob)
    })
}

/**
 *
 * @param {String} dataurl
 * @returns {Promise<Blob>}
 */
export async function getBlobFromDataURL(dataurl) {
    const res = await fetch(dataurl).catch(err => { throw err })
    return res.blob()
}

/**
 *
 * @param {HTMLCanvasElement} canvasElement
 * @returns {Promise<Blob>}
 */
export async function getBlobFromCanvas(canvasElement) {
    return new Promise((resolve, reject) => {
        try {
            canvasElement.toBlob(resolve)
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Callback function to apply when calling html2canvas for print content visualization
 * @param {Document} document
 * @param {HTMLElement} element
 * @param {Object} options
 * @param {String} options.itemToRender
 * @param {Number} options.contentRowIndex
 * @param {String} options.entityType
 * @param {Number} options.entityId
 * @param {Boolean} options.fromPage
 * @param {Array<Number>} options.subRowStructure
 */
export function onCloneCallback(document, element, options = {}) {
    const { itemToRender, contentRowIndex, entityType, entityId, fromPage, subRowStructure, contentType } = options
    try {
        element.className += " print"
        let contentElement = document.getElementById(PAGE_BODY_ID)
        if (entityType === "card") {
            contentElement = fromPage ? document.getElementById(`card-${entityId}`) : document.getElementById(CARD_SCREEN_BODY_ID)
        }
        if (entityType === "collection") {
            contentElement = contentType === "card" ? document.getElementById(CARD_SCREEN_BODY_ID) : document.getElementById(PAGE_BODY_ID)
        }
        const headerElement = document.getElementById(PAGE_HEADER_ID)
        const headerWrapperElement = document.getElementById(PAGE_HEADER_WRAPPER_ID)
        const drawerButtonElement = document.getElementById(DRAWER_MENU_BUTTON_ID)
        const pageHeaderUtilsElement = document.getElementById(PAGE_HEADER_UTILS_ID)
        const filtersElements = document.getElementsByClassName(FILTERS_AREA_CLASS)
        if (itemToRender === "header") {
            if (headerElement) headerElement.className += " print"
            if (entityType === "collection") headerElement.className += " collection-print"
            if (headerWrapperElement) headerWrapperElement.className += " print"
            if (entityType === "collection") headerWrapperElement.className += " collection-print"
            if (drawerButtonElement) drawerButtonElement.className += " display-none"
            if (pageHeaderUtilsElement) pageHeaderUtilsElement.className += " display-none"
            element.className += ` height-${Math.round(headerElement.clientHeight)}`
        } else {
            headerElement.className += " display-none"
        }
        if (itemToRender === "filters") {
            for (let filtersElement of filtersElements) {
                if (filtersElement) {
                    filtersElement.className += " print filters-print"
                    if (entityType === "page") filtersElement.className += " page-print"
                    if (entityType === "collection" && contentType === "page") filtersElement.className += " collection-print page-print"
                    if (entityType === "card") filtersElement.className += " card-print"
                    if (entityType === "collection" && contentType === "card") filtersElement.className += " collection-print card-print"
                }
            }
        } else {
            for (let filtersElement of filtersElements) {
                if (filtersElement) filtersElement.className += " display-none"
            }
        }
        if (itemToRender === "content") {
            if (contentElement) contentElement.className += " print"
            if (entityType === "card") contentElement.className += " card-print"
            if (entityType === "page") contentElement.className += " page-print"
            if (entityType === "collection") {
                if (contentType === "card") contentElement.className += " collection-print card-print"
                if (contentType === "page") contentElement.className += " collection-print page-print"
            }
            const cardFloatingAreas = document.getElementsByClassName(CARD_FLOAT_AREA_CLASS)
            for (let floatArea of cardFloatingAreas) {
                floatArea.className += " display-none"
            }
        } else if (itemToRender !== "content-row" && contentElement) {
            if (contentElement) contentElement.className += " display-none"
        }
        if (itemToRender === "content-row") {
            if (contentElement) contentElement.className += " print"
            if (entityType === "page") contentElement.className += " page-print"
            if (entityType === "collection" && contentType === "page") contentElement.className += " collection-print page-print"
            const cardFloatingAreas = document.getElementsByClassName(CARD_FLOAT_AREA_CLASS)
            for (let floatArea of cardFloatingAreas) {
                floatArea.className += " display-none"
            }
            let cloneContentRowIndex = 0
            for (let children of contentElement?.children) {
                if (contentRowIndex === cloneContentRowIndex) {
                    children.className += ` print page-print height-${Math.round(children.clientHeight) + 10}`
                    if (subRowStructure) {
                        let rowCardIndex = 0
                        for (let rowCard of children.children[0]?.children[0]?.children) {
                            if (!subRowStructure.includes(rowCardIndex)) rowCard.className += " display-none"
                            rowCardIndex++
                        }
                    }
                } else {
                    children.className += " display-none"
                }
                cloneContentRowIndex++
            }
        } else if (itemToRender !== "content") {
            if (contentElement) contentElement.className += " display-none"
        }
    } catch (err) {
        return
    }
}